import { useCallback } from 'react';
import { useAppSelector } from 'src/store/hooks';
import {
  useCreateCampaignMutation,
  useLazyGetCampainDataQuery,
  useSetPreviewSaveMutation,
} from 'src/store/services/create-campaign/endpoints/create-campaign';
import { parseResponse } from 'src/store/services/helpers';

const useSaveAsDraft = (title?: string) => {
  const {
    campaignID,
    segmentID,
    campaignName,
    hypothesis_description,
    googleAds,
    siteUrl,
    googleAdsAccount,
    googleAdsCampaing,
    elements,
    distributionType,
    actionsID,
    status,
  } = useAppSelector((state) => state.createCampaignReducer);
  const [setPreviewSave, { isLoading: previewLoading, error: previewError }] =
    useSetPreviewSaveMutation();
  const [saveAsDraftMutation, { isLoading, error: errorMessage }] =
    useCreateCampaignMutation();
  const [getCampainData] = useLazyGetCampainDataQuery();

  const handlePreviewSave = useCallback(
    async (adID: string, generation?: string) => {
      const response = await setPreviewSave({
        adID,
        body: {
          campaign_id: campaignID,
          segment_id: segmentID,
          hypothesis_description: hypothesis_description,
          name: title || campaignName,
          page_url: siteUrl,
          google_ads_id: googleAdsAccount,
          google_campaign_id: googleAdsCampaing,
          personalized_ads: googleAds.filter((ad) => ad.id === adID) || [],
          elements: elements || [],
          distribution_type: distributionType,
          actions_id: actionsID,
        },
        draftGeneration: generation || '',
      });
      const result = 'data' in response;
      if (result) {
        return true;
      } else {
        return false;
      }
    },
    [
      actionsID,
      campaignID,
      campaignName,
      distributionType,
      elements,
      googleAds,
      googleAdsAccount,
      googleAdsCampaing,
      hypothesis_description,
      segmentID,
      setPreviewSave,
      siteUrl,
      title,
    ]
  );

  const handleSave = useCallback(async () => {
    const response = await saveAsDraftMutation({
      campaign_id: campaignID,
      segment_id: segmentID,
      hypothesis_description: hypothesis_description,
      name: title || campaignName,
      page_url: siteUrl,
      google_ads_id: googleAdsAccount,
      google_campaign_id: googleAdsCampaing,
      personalized_ads: googleAds.filter((ad) => ad.isPersonalized) || [],
      elements: elements || [],
      distribution_type: distributionType,
      actions_id: actionsID,
      status: status,
    });
    const { result } = parseResponse(response);
    if (result) {
      await getCampainData(result.data.id);
    }
    return result ? result.data : { id: '' };
  }, [
    saveAsDraftMutation,
    campaignID,
    segmentID,
    hypothesis_description,
    title,
    campaignName,
    siteUrl,
    googleAdsAccount,
    googleAdsCampaing,
    googleAds,
    elements,
    distributionType,
    actionsID,
    status,
    getCampainData,
  ]);

  return {
    handlePreviewSave,
    handleSave,
    isLoading: isLoading || previewLoading,
    saveError: errorMessage || previewError,
  };
};

export default useSaveAsDraft;
