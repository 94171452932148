import { useCallback, useEffect, useMemo, useState } from 'react';
import { differenceInDays } from 'date-fns';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { setFilters } from 'src/store/slices/campaignSlice';

interface ITimeProp {
  handleChangeInterval: (value: string) => void;
}

const useTimeInterval = ({ handleChangeInterval }: ITimeProp) => {
  const dispatch = useAppDispatch();
  const { filters } = useAppSelector((state) => state.createCampaignReducer);

  const today = useMemo(
    () => (filters.endDate ? new Date(filters.endDate) : new Date()),
    [filters.endDate]
  );
  const lastMonth = useMemo(
    () => new Date(new Date().setDate(today.getDate() - 30)),
    [today]
  );

  const [startDate, setStartDate] = useState(
    filters.startDate ? new Date(filters.startDate) : lastMonth
  );
  const [endDate, setEndDate] = useState(today);

  useEffect(() => {
    if (filters.startDate) setStartDate(new Date(filters.startDate));
    if (filters.endDate) setEndDate(new Date(filters.endDate));
  }, [filters.startDate, filters.endDate]);

  const timeItems = useMemo(() => {
    const daysDiff = differenceInDays(endDate, startDate);
    return [
      {
        label: 'Day',
        value: 'day',
        disable: daysDiff > 60,
      },
      {
        label: 'Week',
        value: 'week',
        disable: daysDiff < 14,
      },
      {
        label: 'Month',
        value: 'month',
        disable: daysDiff < 60,
      },
    ];
  }, [startDate, endDate]);

  const [validValue, setValidValue] = useState(timeItems[0]);

  const findValidTimeInterval = useCallback(() => {
    const validItem = timeItems.find(
      (item) => item.value === filters.part && !item.disable
    );

    if (validItem) {
      return validItem;
    }

    const firstAvailableItem = timeItems.find((item) => !item.disable);
    return firstAvailableItem ? firstAvailableItem : timeItems[0];
  }, [filters.part, timeItems]);

  const handleSelectTimeInterval = useCallback(
    (value: string) => {
      const time = timeItems.find((item) => item.value === value);
      if (time) {
        dispatch(setFilters({ ...filters, part: value }));
        handleChangeInterval(value);
        setValidValue(time);
      }
    },
    [dispatch, filters, handleChangeInterval, timeItems]
  );

  useEffect(() => {
    const currentValidValue = findValidTimeInterval();
    if (
      filters.part !== currentValidValue.value &&
      !currentValidValue.disable
    ) {
      handleSelectTimeInterval(currentValidValue.value);
      setValidValue(currentValidValue);
    }
  }, [
    startDate,
    endDate,
    filters.part,
    handleSelectTimeInterval,
    findValidTimeInterval,
  ]);

  useEffect(() => {
    const timeValue = timeItems.find((item) => item.value === filters.part);
    if (timeValue) {
      handleSelectTimeInterval(timeValue.value);
      setValidValue(timeValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.part]);

  return {
    intervalItems: timeItems,
    intervalValue: validValue,
    handleSelectTimeInterval,
  };
};

export default useTimeInterval;
