import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { googleAPI } from '../services/google/endpoints/google';
import { parseError } from '../services/helpers';
import { SyncStatus, SyncStatusKeys } from '../services/google/types';
import { authAPI } from '../services/auth/endpoints/auth';

export interface googleSyncState {
  signInGoogle: boolean;
  isSyncing: SyncStatusKeys;
  latestSync?: string;
  syncError?: string;
}

const storageSync = (localStorage.getItem('syncing') || 10) as SyncStatusKeys;
const latestSync = localStorage.getItem('latest_syncing') || undefined;
const storageSignInGoogle =
  localStorage.getItem('user_synchronized') === 'true' || false;

const initialState: googleSyncState = {
  isSyncing: storageSync,
  latestSync: latestSync,
  signInGoogle: storageSignInGoogle,
};

const googleSyncSlice = createSlice({
  name: 'googleSync',
  initialState,
  reducers: {
    loadSync: (state) => {
      state.isSyncing = storageSync;
      const latestSync = localStorage.getItem('latest_syncing');
      state.latestSync = latestSync ? latestSync : undefined;
      return state;
    },
    setSync: (
      state,
      action: PayloadAction<{
        isSynced: SyncStatusKeys;
        latestSync?: string;
      }>
    ) => {
      state.latestSync = action.payload.latestSync;
      state.isSyncing = action.payload.isSynced;
      localStorage.setItem('syncing', `${action.payload.isSynced}`);
      if (state.latestSync) {
        localStorage.setItem('latest_syncing', state.latestSync.toString());
      }
      return state;
    },
    setSyncStatus: (state, action: PayloadAction<SyncStatusKeys>) => {
      state.isSyncing = action.payload;
      return state;
    },
    setSyncError: (state, action: PayloadAction<string | undefined>) => {
      state.syncError = action.payload;
      return state;
    },
    setGoogleSignIn: (state, action: PayloadAction<boolean>) => {
      state.signInGoogle = action.payload;
      return state;
    },
  },
  extraReducers(builder) {
    builder
      .addMatcher(
        googleAPI.endpoints.checkIsSyncedGoogle.matchFulfilled,
        (state, action) => {
          if (action.payload) {
            state.isSyncing = action.payload.status_code;
            state.latestSync = action.payload.update_time;
            state.syncError = action.payload.error_text
              ? 'Google Ads synchronization error. Please, contact support'
              : undefined;

            localStorage.setItem('syncing', `${state.isSyncing}`);
            const status =
              SyncStatus[action.payload.status_code as SyncStatusKeys];
            if (state.latestSync && status !== 'CompletedWithError') {
              localStorage.setItem('latest_syncing', state.latestSync);
            }
          } else {
            state.isSyncing = 10;
            state.latestSync = undefined;
            state.signInGoogle = false;
            localStorage.removeItem('syncing');
            localStorage.removeItem('latest_syncing');
            localStorage.removeItem('is_verifyed_url');
            localStorage.removeItem('user_synchronized');
          }
        }
      )
      .addMatcher(
        googleAPI.endpoints.checkIsSyncedGoogle.matchRejected,
        (state, action) => {
          state.isSyncing = 10;
          state.syncError = parseError(action.payload)?.errorMessage;
          localStorage.setItem('syncing', SyncStatus[state.isSyncing]);
          localStorage.removeItem('user_synchronized');
        }
      )
      .addMatcher(
        googleAPI.endpoints.syncGoogleAccount.matchRejected,
        (state, action) => {
          state.isSyncing = 10;
          state.syncError = parseError(action.payload)?.errorMessage;
          localStorage.setItem('syncing', SyncStatus[state.isSyncing]);
        }
      )
      .addMatcher(
        googleAPI.endpoints.getGoogleSignInValidate.matchFulfilled,
        (state, action) => {
          state.signInGoogle = action.payload;
          localStorage.setItem('user_synchronized', `${action.payload}`);
        }
      )
      .addMatcher(authAPI.endpoints.logoutQuery.matchFulfilled, (state) => {
        state.isSyncing = 10;
        state.latestSync = undefined;
        state.syncError = undefined;
        localStorage.removeItem('syncing');
        localStorage.removeItem('latest_syncing');
        return state;
      })
      .addMatcher(authAPI.endpoints.login.matchFulfilled, (state) => {
        state.isSyncing = 10;
        state.latestSync = undefined;
        state.syncError = undefined;
        localStorage.removeItem('syncing');
        localStorage.removeItem('latest_syncing');
        return state;
      });
  },
});

export const { setSync, loadSync, setSyncError, setSyncStatus } =
  googleSyncSlice.actions;
export default googleSyncSlice.reducer;
