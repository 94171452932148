import { HiPencilAlt } from 'react-icons/hi';
import Button from 'src/components/button';

const CampaignHeader = ({
  campaign,
  emptyCampaign,
  handleEditCampaign,
}: any) => {
  return (
    <div className="flex items-center justify-between mb-[42px]">
      <h1 className="text-2xl font-semibold">
        {campaign?.name || emptyCampaign?.name}
      </h1>
      <Button
        className="[&]:px-0 [&]:py-0"
        onClick={(e: any) => handleEditCampaign(e, campaign?.id)}
        type="dark"
        title={
          <a
            className="px-3 py-2 flex items-center"
            href={`/campaigns/update-campaign/${campaign?.id}`}
          >
            <HiPencilAlt /> &nbsp; Edit Personalization Campaign
          </a>
        }
      />
    </div>
  );
};

export default CampaignHeader;
