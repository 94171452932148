import { useEffect, useMemo, useState } from 'react';

import { useGetRecommendationByIdQuery } from 'src/store/services/create-campaign/endpoints/create-campaign';
import { RecommendationsAds } from 'src/store/services/create-campaign/types';

import Spinner from 'src/components/base-spinner';

interface IRecProp {
  campaignID: string;
}

const countElements = (array: RecommendationsAds[]) => {
  const countMap: any = {};
  array.forEach((item) => {
    if (countMap[item.recommendation]) {
      countMap[item.recommendation] += 1;
    } else {
      countMap[item.recommendation] = 1;
    }
  });

  return countMap;
};

const Recommendations = ({ campaignID }: IRecProp) => {
  const { data, isLoading } = useGetRecommendationByIdQuery(campaignID);
  const recObj = useMemo(() => countElements(data || []), [data]);
  const [showSpinner, setShowSpinner] = useState(true);

  useEffect(() => {
    if (!isLoading) {
      const timeoutId = setTimeout(() => {
        setShowSpinner(false);
      }, 500);
      return () => clearTimeout(timeoutId);
    }
  }, [isLoading]);
  return (
    <div className="flex justify-center items-center h-full">
      {showSpinner ? (
        <Spinner size="h-4 w-4" />
      ) : (
        <div className="flex space-x-1 animate-expand">
          <div className="flex items-center justify-center w-5 h-5 bg-red-50 rounded-full animate-fade-in">
            <span className="text-xs font-medium text-red-600">
              {recObj.iterate || 0}
            </span>
          </div>
          <div className="flex items-center justify-center w-5 h-5 bg-gray-100 rounded-full animate-fade-in">
            <span className="text-xs font-medium text-gray-700">
              {recObj.wait || 0}
            </span>
          </div>
          <div className="flex items-center justify-center w-5 h-5 bg-green-100 rounded-full animate-fade-in">
            <span className="text-xs font-medium text-green-800">
              {recObj.statSig || 0}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default Recommendations;
