import { useCallback, useEffect, useState } from 'react';
import { HiRefresh, HiCheckCircle, HiExclamation } from 'react-icons/hi';
import {
  useAddDomainMutation,
  useLazyCheckScriptStatusQuery,
  usePostChangeDomainUrlMutation,
  useRemoveDomainMutation,
} from 'src/store/services/client-domains/endpoints/client-domains';
import { DomainUrlsResponse } from 'src/store/services/client-domains/types';
import { setDomains } from 'src/store/slices/clientDomainSlice';
import Button from 'src/components/button';
import { AddCross } from 'src/components/icons';
import { customToastMessage } from 'src/components/toast';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { parseError } from 'src/store/services/helpers';
import DomainInput from './blocks/domain-input';

const DomainComponent = () => {
  const dispatch = useAppDispatch();
  const { userCopyInput } = useAppSelector((state) => state.storageReducer);
  const { domains } = useAppSelector((state) => state.clientDomainReducer);
  const [domainsInputs, setDomainsInputs] =
    useState<DomainUrlsResponse[]>(domains);
  const [addDomain, { error: addError, isLoading: addLoading }] =
    useAddDomainMutation();
  const [removeDomain, { error: removeError }] = useRemoveDomainMutation();
  const [
    postChangeDomainUrl,
    { error: changeError, isLoading: changeDomainLoading },
  ] = usePostChangeDomainUrlMutation();
  const [checkScriptStatus, { error: checkError, isLoading: checkLoading }] =
    useLazyCheckScriptStatusQuery();
  const [copied, setCopied] = useState(false);
  const [loading, setLoading] = useState(false);

  const copyHandler = useCallback(async () => {
    const text = `
      <!-- Insert the script into the <head> tag on your website -->
      <!-- For optimal performance and a seamless user experience, we recommend placing the client code right after the opening <head> tag of your website. This will ensure that Pipedata AI loads quickly and without any flickering. -->
      <!-- Please avoid using Google Tag Manager -->
      <script id="pipedata-bundle" src="${userCopyInput}" ></script>
    `;
    await navigator.clipboard.writeText(text);

    customToastMessage(
      'The text has been successfully copied',
      <HiCheckCircle className="h-5 w-5" />,
      'success'
    );
    setCopied(true);
    setTimeout(() => setCopied(false), 3000);
  }, [userCopyInput]);

  const handleAddDomain = useCallback(
    (customLocalID: string) => {
      const urlsArr = [
        ...domains,
        {
          id: customLocalID,
          active: false,
          check_date: null,
          client_domain_id: null,
          installed_script_src: null,
          url: '',
          newDomain: true,
        },
      ];
      dispatch(setDomains(urlsArr));
    },
    [domains, dispatch]
  );

  const handleDeleteDomain = useCallback(
    (id: string) => {
      const removedDomainUrl = domains.find((item) => item.id === id);
      const urlsArr = domains.filter((item) => item.id !== id);

      if (removedDomainUrl && !removedDomainUrl.newDomain) {
        removeDomain({ id: removedDomainUrl.id, url: removedDomainUrl.url });
      }
      dispatch(setDomains(urlsArr));
    },
    [domains, dispatch, removeDomain]
  );

  const getStatusType = useCallback(
    (
      activated: boolean,
      activatedDate: Date | null,
      scriptUrl: string | null
    ) => {
      if (activated && activatedDate && scriptUrl === userCopyInput) {
        return 'active';
      }
      return activatedDate ? 'disabled' : 'enactive';
    },
    [userCopyInput]
  );

  const handleAddAndCheckDomains = useCallback(async () => {
    const domainChanged = domains.filter((domain) => domain.urlChange);
    const domainAdded = domains.filter((domain) => domain.newDomain);

    await Promise.all([
      ...domainAdded.map((item) => addDomain({ url: item.url })),
      ...domainChanged.map((item) =>
        postChangeDomainUrl({ id: item.id, url: item.url })
      ),
    ]);

    domains
      .filter((item) => !item.id.includes('D'))
      .forEach((item) => checkScriptStatus({ id: item.id }));
  }, [domains, addDomain, postChangeDomainUrl, checkScriptStatus]);

  useEffect(() => {
    if (addError || changeError || removeError || checkError) {
      const { errorMessage } = parseError(
        addError || changeError || removeError || checkError
      );
      customToastMessage(
        errorMessage,
        <HiExclamation className="h-5 w-5" />,
        'error'
      );
    }
  }, [addError, changeError, removeError, checkError]);

  useEffect(() => {
    setLoading(addLoading || changeDomainLoading || checkLoading);
  }, [addLoading, changeDomainLoading, checkLoading]);

  useEffect(() => {
    setDomainsInputs(domains);
  }, [domains]);

  return (
    <div className="flex flex-col gap-y-[18px]">
      <div className="text-lg font-bold text-gray-900">
        1. Enter your website URL:
      </div>
      {domainsInputs.map((domain) => (
        <DomainInput
          key={domain.id}
          id={domain.id}
          url={domain.url || ''}
          status={getStatusType(
            domain.active,
            domain.check_date,
            domain.installed_script_src
          )}
          onDeleteSubdomain={() => handleDeleteDomain(domain.id)}
          isLast={domains.length <= 1}
        />
      ))}
      <Button
        type="outline"
        title="Add domain or page"
        onClick={() => handleAddDomain(`D-ID-${Math.random()}`)}
        className="max-w-max"
        Icon={<AddCross color="#1A56DB" className="mr-2" />}
      />
      <div className="text-lg font-bold text-gray-900">
        2. Copy your unique client script
      </div>
      <div className="bg-gray-100 rounded-lg overflow-hidden py-[6px] px-[8px] text-gray-800 text-xs font-semibold flex gap-x-[10px] border border-gray-200">
        <div className="flex items-center w-full">
          {`<script id="pipedata-bundle" src="${userCopyInput}"></script>`}
          <button
            data-testid="copy_button"
            className="text-primary-500 cursor-pointer"
            onClick={copyHandler}
          >
            {copied ? 'Copied' : 'Copy'}
          </button>
        </div>
      </div>
      <div className="text-lg font-bold text-gray-900">
        3. Add the script to your website&apos;s &lt;head&gt; tag
        <br />
        <span className="text-gray-500 font-normal">
          To ensure Pipedata loads quickly and without any flickering:
          <ul className="ml-5">
            <li className="list-disc">
              place the script as high as possible in the &lt;head&gt; tag
            </li>
            <li className="list-disc">avoid using Google Tag Manager</li>
          </ul>
        </span>
      </div>
      <div className="text-lg font-bold text-gray-900">
        4. Verify the script installed correctly.
        <br />
        <span className="text-gray-500 font-normal">
          Once you have inserted the script, you can confirm that it has been
          installed correctly by updating the status below.
        </span>
      </div>
      <div className="mt-[6px] flex justify-between">
        <Button
          type="default"
          title="Check Status"
          onClick={handleAddAndCheckDomains}
          Icon={
            <HiRefresh
              className={`mr-2 h-4 w-4 ${loading ? 'animate-spin-fast' : ''}`}
            />
          }
        />
      </div>
    </div>
  );
};

export default DomainComponent;
