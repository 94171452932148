import { useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  useStoreGoogleTokensMutation,
  useLazySyncGoogleAccountQuery,
} from '../store/services/google/endpoints/google';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { setSync } from '../store/slices/googleSyncSlice';
import { SyncStatus } from 'src/store/services/google/types';
import Integration from 'src/features/integration/integration';
import BreadCrumbs from 'src/components/bread-crumbs';

export function IntegrationPage() {
  const dispatch = useAppDispatch();
  const [storeGoogleTokens, { isLoading }] = useStoreGoogleTokensMutation();
  const [syncGoogleAccount] = useLazySyncGoogleAccountQuery();
  const { isSyncing } = useAppSelector((state) => state.googleSyncReducer);
  const [searchParams, setSearchParams] = useSearchParams();

  const codeListenerParams = useCallback(async () => {
    const code = searchParams.get('code') ?? '';

    if (SyncStatus[isSyncing] === SyncStatus[2]) return;
    if (code.length > 0) {
      try {
        searchParams.delete('code');
        searchParams.delete('scope');
        setSearchParams(searchParams);
        await storeGoogleTokens({ code: code });
        await syncGoogleAccount();
        dispatch(setSync({ isSynced: 2 }));
      } catch (e) {
        console.log('ERROR: ', e);
      }
    }

    return;
  }, [
    dispatch,
    isSyncing,
    searchParams,
    setSearchParams,
    storeGoogleTokens,
    syncGoogleAccount,
  ]);

  useEffect(() => {
    codeListenerParams();
  }, [codeListenerParams]);

  return (
    <section className="h-auto">
      <div className="block items-center justify-between py-4 pt-6 px-10">
        <div className="mb-1 w-full">
          <div className="mb-4">
            <BreadCrumbs
              items={[{ title: 'Integrations', link: '/integrations' }]}
            />
          </div>
          <h1 className="text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
            Integrations
          </h1>
        </div>
      </div>

      <Integration isLoading={isLoading} />
    </section>
  );
}
