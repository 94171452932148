import { calcCR } from 'src/helpers/cdf';
import { parseResponse } from 'src/store/services/helpers';
import { useGetAllSegmentsQuery } from 'src/store/services/segments/endpoints/segments';
import { TSegment } from '../constants';

const useSegmentsDataTransform = () => {
  const { isLoading, data, error, isFetching } = useGetAllSegmentsQuery();
  const { result, errorMessage } = parseResponse({ data, error });
  const transformData: TSegment[] = [];
  if (result) {
    result.data.map((segment) => {
      transformData.push({
        id: segment.segments_id,
        segmentName: segment.segments_name,
        visitors: segment.experimental_visitors + segment.full_visitors,
        cr: calcCR(
          segment.full_visitors + segment.experimental_visitors,
          segment.full_leads + segment.experimental_leads
        ),
        leads: segment.experimental_leads + segment.full_leads,
        avg: segment.avg_session,
        bounce: segment.bounce ? Math.round(segment.bounce) + '%' : '-',
        campaignsCount: segment.company_count,
      });
    });
  }
  return {
    isLoading,
    isFetching,
    segments: transformData,
    error: result ? undefined : errorMessage,
  };
};

export default useSegmentsDataTransform;
