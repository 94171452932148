import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import BaseSelect from './base-select';
import { SelectOption } from './simple-select';
import {
  ClearCross,
  SearchIcon,
  StatusEnabled,
  StatusPaused,
  StatusRemoved,
  StatusUnknown,
} from '../icons';

export interface IGroupItem {
  groupName: string;
  groupItems: SelectOption[];
}

interface IGroupProp {
  title?: string;
  value?: string;
  items: IGroupItem[];
  onSelect: (selectedValue: string) => void;
  itemClassName?: string;
  disabled?: boolean;
  loading?: boolean;
  searchPlaceholder?: string;
  baseClassName?: string;
  popupClassName?: string;
}

const STATUS_ICON = {
  Enabled: (
    <StatusEnabled
      key="StatusEnabled"
      className="min-w-[14px] min-h-[14px] mr-2"
    />
  ),
  Paused: (
    <StatusPaused
      key="StatusPaused"
      className="min-w-[14px] min-h-[14px] mr-2"
    />
  ),
  Removed: (
    <StatusRemoved
      key="StatusRemoved"
      className="min-w-[14px] min-h-[14px] mr-2"
    />
  ),
  Unknown: (
    <StatusUnknown
      key="StatusUnknown"
      className="min-w-[14px] min-h-[14px] mr-2"
    />
  ),
  Unspecified: (
    <StatusUnknown
      key="StatusUnknown"
      className="min-w-[14px] min-h-[14px] mr-2"
    />
  ),
};

const GroupSelect = ({
  title,
  value,
  items,
  onSelect,
  itemClassName,
  disabled,
  loading,
  searchPlaceholder,
  baseClassName,
  popupClassName,
}: IGroupProp) => {
  const [searchValue, setSearchValue] = useState('');
  const searchInputRef = useRef<HTMLInputElement | null>(null);

  const filtered = useMemo(() => {
    const matchingItems: IGroupItem[] = [];

    items.forEach((option: IGroupItem) => {
      const matchingGroupItems: SelectOption[] = option.groupItems.filter(
        (groupItem) => {
          const lowerLabel = groupItem.label.toLowerCase();
          const lowerSearchValue = searchValue.toLowerCase();

          return (
            lowerLabel.startsWith(lowerSearchValue) ||
            lowerLabel.includes(lowerSearchValue)
          );
        }
      );

      if (matchingGroupItems.length > 0) {
        matchingItems.push({
          groupName: option.groupName,
          groupItems: matchingGroupItems.sort((a, b) =>
            a.label.localeCompare(b.label)
          ),
        });
      }
    });

    return matchingItems.length === 0 && !searchValue ? items : matchingItems;
  }, [items, searchValue]);

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      e.stopPropagation();
      setSearchValue(e.target.value);
    },
    []
  );

  const handleClearSearch = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      setSearchValue('');
      if (searchInputRef.current) {
        searchInputRef.current.focus();
      }
    },
    []
  );

  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [searchValue]);

  return (
    <BaseSelect
      title={title}
      baseClassName={baseClassName}
      popupClassName={`[&]:p-0 [&]:max-w-none [&]:max-h-[270px] ${popupClassName}`}
      placeholder={value || items[0].groupItems[0].label}
      disabled={disabled}
      isLoad={loading}
      ItemList={(handleClose) => (
        <div
          onClick={(e) => {
            e.preventDefault(), e.stopPropagation();
          }}
        >
          <div className="sticky pb-3 px-3 pt-2 top-0 w-full bg-white">
            <label className="relative flex items-center">
              <SearchIcon className="absolute left-4 pointer-events-none" />
              <input
                ref={searchInputRef}
                className="w-full h-10 bg-[#F9FAFB] flex items-center gap-2.5 self-stretch border border-[color:var(--gray-300,#D1D5DB)] pl-10 px-3 py-3 rounded-lg border-solid 
                  placeholder:text-[color:var(--gray-500,#6B7280)] placeholder:text-sm placeholder:not-italic placeholder:font-normal placeholder:leading-[125%]"
                type="text"
                placeholder={searchPlaceholder || 'Search...'}
                value={searchValue}
                onChange={handleSearchChange}
                aria-label="Search"
              />
              <button
                className="absolute right-4 cursor-pointer"
                onClick={handleClearSearch}
                aria-label="Clear search"
              >
                <ClearCross />
              </button>
            </label>
          </div>
          {filtered.map((item, index) => (
            <div
              key={`Select-${item.groupName}-${index}`}
              role="group"
              aria-label={item.groupName}
            >
              {item.groupItems.map((groupItem) => (
                <button
                  key={`${groupItem.value}-${groupItem.label}`}
                  className={`w-full pb-1.5 px-3 text-left hover:bg-gray-100 flex items-center justify-start pt-2 pb-2	${
                    value && value.includes(groupItem.label)
                      ? '[&]:bg-green-100'
                      : ''
                  } ${groupItem.disable ? '[&]:cursor-pointer' : ''}`}
                  onClick={(e) => {
                    e.preventDefault();
                    onSelect(groupItem.value);
                    handleClose(e);
                  }}
                  disabled={groupItem.disable}
                  role="option"
                  aria-selected={groupItem.value === value ? 'true' : 'false'}
                >
                  {STATUS_ICON[item.groupName as keyof typeof STATUS_ICON]}
                  <span
                    className={`leading-tight text-xs font-medium text-gray-900 ${itemClassName} ${
                      groupItem.disable && groupItem.label !== value
                        ? '[&&]:text-gray-400'
                        : ''
                    } ${groupItem.label === value ? '[&]:text-black' : ''}`}
                  >
                    {groupItem.label}
                  </span>
                </button>
              ))}
            </div>
          ))}
        </div>
      )}
    />
  );
};

export default GroupSelect;
