import { forwardRef, InputHTMLAttributes } from 'react';
import { Tooltip } from 'react-tooltip';
import { ClearCross, IIcon, InfoIcon, Trush } from './icons';

interface IInputProps extends InputHTMLAttributes<HTMLInputElement> {
  id: string;
  title?: string;
  tooltipText?: string;
  onClearClick?: () => void;
  onDeletedClick?: () => void;
  className?: string;
  error?: string;
}

const Input = forwardRef<HTMLInputElement, IInputProps>(
  (
    {
      id,
      title,
      tooltipText,
      onDeletedClick,
      onClearClick,
      className = '',
      error,
      ...rest
    },
    ref
  ) => {
    return (
      <div className={className}>
        {title && (
          <label
            htmlFor={id}
            className={`flex items-center mb-2 text-sm font-medium ${
              error ? 'text-red-500' : 'text-gray-900'
            }`}
          >
            {title}
            {tooltipText && (
              <div className="ml-2.5">
                <InfoIcon
                  data-tooltip-place="top-start"
                  data-tooltip-id={`input-tooltip-${title}`}
                  data-tooltip-content={tooltipText}
                  {...({
                    'aria-describedby': `${id}-tooltip`,
                    role: 'tooltip',
                  } as IIcon)}
                />
              </div>
            )}
          </label>
        )}
        <div className="flex items-center w-full">
          <div className="relative w-full max-w-[364px]">
            <input
              ref={ref}
              id={id}
              className={`"max-w-[364px] w-full bg-gray-50 border ${
                error ? 'border-red-500' : 'border-gray-300'
              } bg-white text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block py-2 px-4 disabled:text-gray-400 disabled:bg-gray-100`}
              {...rest}
            />
            {onClearClick && rest.value && (
              <button
                className="absolute right-[16px] top-1/2 translate-y-[-50%]"
                type="button"
                onClick={onClearClick}
                aria-label="Clear input"
              >
                <ClearCross />
              </button>
            )}
          </div>
          {onDeletedClick && (
            <button
              className="ml-3"
              type="button"
              onClick={onDeletedClick}
              aria-label="Delete input"
            >
              <Trush />
            </button>
          )}
        </div>
        {error && <p className="mt-2 text-sm text-red-500">{error}</p>}
        <Tooltip id={`input-tooltip-${title}`} style={{ zIndex: 60 }} />
      </div>
    );
  }
);

Input.displayName = 'Input';

export default Input;
