import {
  useGetAllCampaignQuery,
  useLazyGetAllCampaignQuery,
  useLazyGetCampainDataQuery,
  usePauseCampaignMutation,
  usePublishAndValidateCampaignMutation,
} from '../../store/services/create-campaign/endpoints/create-campaign';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  CampaignItemStatus,
  FilterCalendarDate,
} from '../../store/services/create-campaign/types';
import { isSignificant } from '../../helpers/cdf';
import { HiCheckCircle, HiExclamation } from 'react-icons/hi';
import { parseResponse } from '../../store/services/helpers';
import { customToastMessage } from '../../components/toast';
import DashboardTable from './blocks/dashboard-table';
import EmptyList from 'src/empty-list';
import CampaignsOverviewComponent from './blocks/campaigns-overview';
import { setGlobalLoading } from 'src/store/slices/storageSlice';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { findOldestDate } from './hooks/getOldestCampaign';
import { setFilters } from 'src/store/slices/campaignSlice';

interface ICampaignsProps {
  syncInProgress: boolean;
  handleRedirectPage: (redurectValue: string) => void;
}

export function Campaigns({
  syncInProgress,
  handleRedirectPage,
}: ICampaignsProps) {
  const dispatch = useAppDispatch();
  const [load, setLoad] = useState(false);
  const { filters } = useAppSelector((state) => state.createCampaignReducer);

  const today = useMemo(
    () => (filters.endDate ? new Date(filters.endDate) : new Date()),
    [filters.endDate]
  );
  const last_month = useMemo(
    () =>
      new Date(
        filters.startDate
          ? new Date(filters.startDate)
          : new Date().setDate(today.getDate() - 30)
      ),
    [filters.startDate, today]
  );
  const [startDay, setStartDay] = useState(last_month);
  const [endDay, setEndDay] = useState(today);

  const { data, isLoading: allCampaignLoading } = useGetAllCampaignQuery({
    startDate: Intl.DateTimeFormat('sv-SE').format(startDay),
    endDate: Intl.DateTimeFormat('sv-SE').format(endDay),
  });
  const [getAllCampaign, { isLoading, isFetching }] =
    useLazyGetAllCampaignQuery();

  const campaigns = useMemo(() => data?.data?.campaigns || undefined, [data]);

  const [publishAndValidateCampaign, { isLoading: publishLoading }] =
    usePublishAndValidateCampaignMutation();
  const [pauseCampaign, { isLoading: pauseLoading }] =
    usePauseCampaignMutation();
  const [
    getCampaignData,
    { isLoading: campaignLoading, isFetching: campaignFetching },
  ] = useLazyGetCampainDataQuery();

  const handleEditCampaign = async (id: number) => {
    const { data, error } = await getCampaignData(id.toString());
    const { result, errorMessage } = parseResponse({ data, error });

    if (error && errorMessage) {
      customToastMessage(
        errorMessage,
        <HiExclamation className="h-5 w-5" />,
        'error',
        200
      );
    }
    if (result && !syncInProgress) {
      handleRedirectPage(`/campaigns/update-campaign/${id}`);
    }
  };

  const loadCampanies = useCallback(
    async (startDate: Date, endDate: Date) => {
      const params = {} as FilterCalendarDate;

      params.startDate = Intl.DateTimeFormat('sv-SE').format(startDate);
      params.endDate = Intl.DateTimeFormat('sv-SE').format(endDate);

      const { data, error } = await getAllCampaign(params);
      const { errorMessage } = parseResponse({ data, error });
      if (error && errorMessage) {
        customToastMessage(
          errorMessage,
          <HiExclamation className="h-5 w-5" />,
          'error',
          200
        );
      }

      dispatch(
        setFilters({
          startDate: Intl.DateTimeFormat('en-US').format(startDate),
          endDate: Intl.DateTimeFormat('en-US').format(endDate),
        })
      );
      setStartDay(startDate);
      setEndDay(endDate);
    },
    [dispatch, getAllCampaign]
  );

  const getVisitors = () => {
    if (campaigns) {
      return campaigns
        .filter(
          (campaign) =>
            campaign.status === CampaignItemStatus.Active &&
            campaign.distribution_type <= 50
        )
        .reduce((acc, e) => acc + e.full_visitors, 0);
    }
    return 0;
  };

  const getExperimentalVisitors = () => {
    if (
      campaigns &&
      campaigns.filter(
        (campaign) =>
          campaign.status === CampaignItemStatus.Active &&
          campaign.distribution_type <= 50
      )
    ) {
      return campaigns
        .filter(
          (campaign) =>
            campaign.status === CampaignItemStatus.Active &&
            campaign.distribution_type <= 50
        )
        .reduce((acc, e) => acc + e.experimental_visitors, 0);
    }
    return 0;
  };

  const getLeads = () => {
    if (
      campaigns &&
      campaigns.filter(
        (campaign) =>
          campaign.status === CampaignItemStatus.Active &&
          campaign.distribution_type <= 50
      )
    ) {
      return campaigns
        .filter(
          (campaign) =>
            campaign.status === CampaignItemStatus.Active &&
            campaign.distribution_type <= 50
        )
        .reduce((acc, e) => acc + e.full_leads, 0);
    }
    return 0;
  };

  const getExperimentalLeads = () => {
    if (
      campaigns &&
      campaigns.filter(
        (campaign) =>
          campaign.status === CampaignItemStatus.Active &&
          campaign.distribution_type <= 50
      )
    ) {
      return campaigns
        .filter(
          (campaign) =>
            campaign.status === CampaignItemStatus.Active &&
            campaign.distribution_type <= 50
        )
        .reduce((acc, e) => acc + e.experimental_leads, 0);
    }
    return 0;
  };

  const handlePublishCampaign = async (id: number) => {
    const response = await publishAndValidateCampaign(id);

    const { result, errorMessage, error } = parseResponse(response);
    if (error && errorMessage) {
      customToastMessage(
        errorMessage,
        <HiExclamation className="h-5 w-5" />,
        'error',
        200
      );
    }

    if (result) {
      customToastMessage(
        result.message,
        <HiCheckCircle className="h-5 w-5" />,
        'success'
      );
    }
  };

  const handlePauseCampaign = async (id: number) => {
    const response = await pauseCampaign(id);

    const { result, errorMessage, error } = parseResponse(response);
    if (error && errorMessage) {
      customToastMessage(
        errorMessage,
        <HiExclamation className="h-5 w-5" />,
        'error',
        200
      );
    }
    if (result) {
      customToastMessage(
        result.message,
        <HiCheckCircle className="h-5 w-5" />,
        'success'
      );
    }
  };

  useEffect(() => {
    dispatch(setGlobalLoading(campaignLoading || campaignFetching));
  }, [dispatch, campaignLoading, campaignFetching]);

  useEffect(() => {
    setLoad(
      isFetching ||
        isLoading ||
        pauseLoading ||
        publishLoading ||
        allCampaignLoading
    );
  }, [isFetching, isLoading, pauseLoading, publishLoading, allCampaignLoading]);

  return !load && campaigns && campaigns.length <= 0 ? (
    <EmptyList title="Let’s Create your first personalization for your Google Ads campaign" />
  ) : (
    <>
      <CampaignsOverviewComponent
        onChangeDate={(start, end) => loadCampanies(start, end)}
        onChangeVersion={() => null}
        onChangeAds={() => null}
        onChangeDevice={() => null}
        onChangeConversion={() => null}
        onChangeInterval={() => null}
        lastCampaignDate={findOldestDate(campaigns || [], load)}
        visitors={getVisitors()}
        experimental_visitors={getExperimentalVisitors()}
        leads={getLeads()}
        experimental_leads={getExperimentalLeads()}
        isSignificant={isSignificant(
          getLeads(),
          getVisitors(),
          getExperimentalLeads(),
          getExperimentalVisitors()
        )}
        show={true}
        isLoading={load}
      />
      <DashboardTable
        campaigns={campaigns}
        handleEditCampaign={handleEditCampaign}
        handlePauseCampaign={handlePauseCampaign}
        handlePublishCampaign={handlePublishCampaign}
        isLoading={load}
      />
    </>
  );
}
