import { useMemo, useCallback } from 'react';
import Table from 'src/components/table/table';
import Chart, { ChartSkeleton } from 'src/components/chart/chart';
import { isSignificant } from 'src/helpers/cdf';
import {
  ChartData,
  DetailCampaignAd,
  FilterCalendarDate,
  TStatistic,
} from 'src/store/services/create-campaign/types';
import { TABLE_DETAIL_COLUMN } from './constants';
import useDetailDataTransform from './hooks/useDetailDataTransform';
import CampaignsOverviewComponent from '../campaigns/blocks/campaigns-overview';
import StatusLine, {
  StatusLineSkeleton,
} from '../campaigns/blocks/status-line';

interface IDetailProp {
  campaignID: string;
  url: string;
  ads: DetailCampaignAd[];
  filters: FilterCalendarDate;
  setFilterParams: (param: FilterCalendarDate) => void;
  handleOpenPreview: (id: string, type: string) => void;
  lastCampaignDate: Date;
  chartData: ChartData | null;
  total?: TStatistic;
  isLoading?: boolean;
  chartLoading?: boolean;
  chartFilterType?: string;
}

const DetailCampaign = ({
  campaignID,
  ads,
  filters,
  setFilterParams,
  handleOpenPreview,
  lastCampaignDate,
  chartData,
  total,
  isLoading,
  chartLoading,
  chartFilterType,
}: IDetailProp) => {
  const { adsTransform } = useDetailDataTransform(ads, campaignID);

  const totalLine = useMemo(() => {
    return total
      ? {
          experimental_conversions: total.experimental_conversions || 0,
          experimental_visitors: total.experimental_visitors || 0,
          ctrl_conversions: total.ctrl_conversions || 0,
          ctrl_visitors: total.ctrl_visitors || 0,
        }
      : {
          experimental_conversions: 0,
          experimental_visitors: 0,
          ctrl_conversions: 0,
          ctrl_visitors: 0,
        };
  }, [total]);

  const handleDateChange = useCallback(
    (start: Date, end: Date) => {
      setFilterParams({
        ...filters,
        id: campaignID,
        startDate: Intl.DateTimeFormat('sv-SE').format(start),
        endDate: Intl.DateTimeFormat('sv-SE').format(end),
      });
    },
    [campaignID, filters, setFilterParams]
  );

  const handleVersionChange = useCallback(
    (version: string) => {
      setFilterParams({
        ...filters,
        id: campaignID,
        version: version,
      });
    },
    [campaignID, filters, setFilterParams]
  );

  const handleAdsChange = useCallback(
    (ads: string[]) => {
      setFilterParams({
        ...filters,
        id: campaignID,
        ads: ads,
      });
    },
    [campaignID, filters, setFilterParams]
  );

  const handleDeviceChange = useCallback(
    (deviceValue: string) => {
      setFilterParams({
        ...filters,
        id: campaignID,
        is_desktop: deviceValue,
      });
    },
    [campaignID, filters, setFilterParams]
  );

  const handleConversionChange = useCallback(
    (conversions: string) => {
      setFilterParams({
        ...filters,
        id: campaignID,
        conversions: conversions,
      });
    },
    [campaignID, filters, setFilterParams]
  );

  const handleIntervalChange = useCallback(
    (interval: string) => {
      setFilterParams({
        ...filters,
        id: campaignID,
        part: interval,
      });
    },
    [campaignID, filters, setFilterParams]
  );

  return (
    <div>
      {isLoading || !ads[0] ? (
        <StatusLineSkeleton />
      ) : (
        <StatusLine
          status={ads[0].campaign.user_campaign.status}
          distribution={ads[0].campaign.user_campaign.distribution_type}
          googleCampaignTitle={ads[0].campaign.name}
          createdAt={ads[0].campaign.user_campaign.created_at}
          campaignURL={ads[0].campaign.user_campaign.page_url}
        />
      )}
      <CampaignsOverviewComponent
        onChangeDate={handleDateChange}
        onChangeVersion={handleVersionChange}
        onChangeAds={handleAdsChange}
        onChangeDevice={handleDeviceChange}
        onChangeConversion={handleConversionChange}
        onChangeInterval={handleIntervalChange}
        visitors={totalLine.ctrl_visitors}
        experimental_visitors={totalLine.experimental_visitors}
        leads={totalLine.ctrl_conversions}
        experimental_leads={totalLine.experimental_conversions}
        isSignificant={isSignificant(
          totalLine.experimental_conversions,
          totalLine.experimental_visitors,
          totalLine.ctrl_conversions,
          totalLine.ctrl_visitors
        )}
        show={true}
        lastCampaignDate={lastCampaignDate}
        ads={ads}
        isLoading={isLoading}
      />
      {chartLoading ? (
        <div className="py-6">
          <ChartSkeleton />
        </div>
      ) : (
        chartData && (
          <div className="py-6">
            <Chart data={chartData} filterType={chartFilterType} />
          </div>
        )
      )}
      <div className="py-4">
        <Table
          type="light"
          data={adsTransform}
          columns={TABLE_DETAIL_COLUMN({
            handleOpenPreview,
          })}
          stickHead
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default DetailCampaign;
