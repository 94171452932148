import { useState } from 'react';
import { HiChevronRight } from 'react-icons/hi';

type TAccordion = {
  title: string;
  content: JSX.Element;
};

interface IAccordionProps {
  accordionItems: TAccordion[];
}

const BaseAccordion = ({ accordionItems }: IAccordionProps) => {
  const [openPanels, setOpenPanels] = useState<string[]>([]);

  const togglePanel = (panelId: string) => {
    if (openPanels.includes(panelId)) {
      setOpenPanels(openPanels.filter((id) => id !== panelId));
    } else {
      setOpenPanels([...openPanels, panelId]);
    }
  };

  return (
    <div className="divide-y divide-gray-200">
      {accordionItems.map((item, index) => {
        const panelId = `${item.title}-${index}`;
        const isOpen = openPanels.includes(panelId);

        return (
          <div key={panelId}>
            <button
              onClick={() => togglePanel(panelId)}
              className="flex justify-between w-full py-2 px-4 text-left text-sm font-medium text-gray-900 bg-white dark:bg-gray-800 dark:text-white focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75"
            >
              {item.title}
              <span
                className={`ml-2 transition-transform transform ${
                  isOpen ? 'rotate-180' : 'rotate-0'
                }`}
              >
                <HiChevronRight size={16} className="text-gray-900 rotate-90" />
              </span>
            </button>
            {isOpen && (
              <div className="p-4 pt-0 text-sm text-gray-500 dark:text-gray-400">
                {item.content}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default BaseAccordion;
