import { differenceInDays, differenceInHours, format } from 'date-fns';
import ReactApexChart from 'react-apexcharts';
import { calcCR } from 'src/helpers/cdf';
import { ChartData } from 'src/store/services/create-campaign/types';

interface IChartProp {
  data: ChartData;
  filterType?: string;
}

const Chart = ({ data, filterType }: IChartProp) => {
  const personalizedCr = () => {
    const { visitor_count, conversion_count } = data.experimental;
    const crArr = [];
    for (let i = 0; i < visitor_count.length; i++) {
      const calculation = calcCR(
        visitor_count[i] || 0,
        conversion_count[i] || 0
      );
      crArr.push(calculation);
    }
    return crArr;
  };

  const controlCr = () => {
    const { visitor_count, conversion_count } = data.control;
    const crArr = [];
    for (let i = 0; i < visitor_count.length; i++) {
      const calculation = calcCR(
        visitor_count[i] || 0,
        conversion_count[i] || 0
      );
      crArr.push(calculation);
    }
    return crArr;
  };

  const options = {
    xaxis: {
      show: true,
      categories: data.axisX.flat(1),
      labels: {
        show: true,
        style: {
          cssClass: 'text-xs font-normal fill-gray-400 dark:fill-gray-400',
        },
        format: 'MMM dd',
        formatter: (value: any) => {
          const isInHours = differenceInDays(
            new Date(data.axisX.flat(1)[0]),
            new Date(data.axisX.flat(1)[data.axisX.length - 1])
          );

          const checkIsHours = filterType === 'hour';
          const length = checkIsHours
            ? (isInHours - 1) * 12 * -1
            : data.axisX.flat(1).length;

          let range = 1;
          while (range * 10 < length) {
            range++;
          }
          const startDate = new Date(data.axisX.flat(1)[0]);
          const date = new Date(value);

          const diff = checkIsHours
            ? differenceInHours(startDate, date)
            : differenceInDays(startDate, date);
          if (isNaN(date.getTime())) {
            return value;
          }
          const label = format(date, checkIsHours ? 'dd hh:mm' : 'MMM d');
          if ((diff % range) * 10 === 0) return label;
          return '';
        },
        tooltip: {
          enabled: false,
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: true,
        borderType: 'solid',
      },
    },
    yaxis: {
      show: true,
      labels: {
        show: true,
        style: {
          cssClass: 'text-xs font-normal fill-gray-500 dark:fill-gray-400',
        },
        formatter: (value: any) => {
          return value + '%';
        },
      },
    },
    series: [
      {
        name: 'Personalized CR',
        data: personalizedCr(),
        color: '#3F83F8',
      },
      {
        name: 'Control CR',
        data: controlCr(),
        color: '#D1D5DB',
      },
    ],
    chart: {
      type: 'line' as ApexChart['type'],
      height: '100%',
      width: '100%',
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      enabled: true,
      custom: ({ series, dataPointIndex }: any) => {
        return `
        <div class="w-full min-w-[275px] py-2.5 px-4">
          <p class="text-xs font-normal text-gray-500">${format(
            new Date(data.axisX[dataPointIndex]),
            'EEE, MMM dd, yyyy'
          )}</p>
          <div class="flex items-center justify-between my-1.5">
            <div class="flex items-center">
              <div class="mr-1 h-2 w-2 bg-blue-500 rounded-full"></div>
              <p class="text-xs font-normal text-gray-900">Personalized</p>
            </div>
            <p class="text-xs font-normal text-blue-500">
              ${data.experimental.visitor_count[dataPointIndex] || 0} vis / ${
          series[0][dataPointIndex] || 0
        }% / ${data.experimental.conversion_count[dataPointIndex] || 0} conv
            </p>
          </div>
  
          <div class="flex items-center justify-between">
            <div class="flex items-center">
              <div class="mr-1 h-2 w-2 bg-gray-300 rounded-full"></div>
              <p class="text-xs font-normal text-gray-900">Control</p>
            </div>
            <p class="text-xs font-normal text-gray-900">
              ${data.control.visitor_count[dataPointIndex] || 0} vis / ${
          series[1][dataPointIndex] || 0
        }% / ${data.control.conversion_count[dataPointIndex] || 0} conv
            </p>
          </div>
        </div>`;
      },
      x: {
        show: true,
      },
    },
    fill: {
      type: 'solid',
      opacity: 0,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 2,
    },
    legend: {
      show: true,
    },
    grid: {
      show: true,
      borderColor: '#E5E7EB',
      strokeDashArray: 7,
    },
  };

  return (
    <div id="chart" className="bg-white shadow-md rounded-lg">
      <ReactApexChart
        options={options}
        series={options.series}
        type="area"
        height="312"
      />
    </div>
  );
};

export const ChartSkeleton = () => {
  return (
    <div className="bg-white shadow-md rounded-lg animate-pulse p-4">
      <div className="h-[294px] bg-gray-200 rounded-lg"></div>
    </div>
  );
};

export default Chart;
