export const getElementActionName = (element: any) => {
  const elementTag = element.tagName.toLowerCase();
  if (elementTag === 'a') {
    return 'Link';
  }
  if (elementTag === 'form') {
    return 'Form';
  }
  return 'Button';
};
