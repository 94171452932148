import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { HiExclamation, HiCheckCircle } from 'react-icons/hi';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import {
  setCampaignHypothesis,
  setCampaignName,
} from 'src/store/slices/campaignSlice';
import { setCampaignId } from 'src/store/slices/campaignSlice';
import { setGlobalLoading, setNextStep } from 'src/store/slices/storageSlice';
import { parseError } from 'src/store/services/helpers';
import { useGetCampainDataQuery } from 'src/store/services/create-campaign/endpoints/create-campaign';

import Input from 'src/components/input';
import { customToastMessage } from 'src/components/toast';
import Textarea from 'src/components/textarea';
import SimpleSelect from 'src/components/selects/simple-select';
import GroupSelect from 'src/components/selects/group-select';

import CreateCampaignFooter from './blocks/create-campaign-footer';
import useSelectAccountGoogleADS from './hooks/useSelectAccountGoogleADS';
import useSelectCampaignGoogleADS from './hooks/useSelectCampaignGoogleADS';
import useSaveAsDraft from './hooks/useSaveAsDraft';
import { TRAFFIC_SOURCE as TRAFFIC_SOURCE } from './constants';
import useSelectSegment from './hooks/useSelectSegment';

const StepOne = () => {
  const { id } = useParams();
  const { pathname } = useLocation();
  const [nextLoad, setNextLoad] = useState(false);
  useGetCampainDataQuery(id as string, { skip: !id });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { campaignName, campaignID, hypothesis_description } = useAppSelector(
    (state) => state.createCampaignReducer
  );

  const isUpdateCampaign = useMemo(
    () => pathname.includes('update'),
    [pathname]
  );

  const [title, setTitle] = React.useState(campaignName);

  const {
    campaigns,
    campaignsLoading,
    activeCampaign,
    handleSelectedCampaign,
    googleCampaignError,
  } = useSelectCampaignGoogleADS();

  const { accounts, activeAccount, handleSelectedAccount, googleAdsError } =
    useSelectAccountGoogleADS({ handleSelectedCampaign });

  const {
    segments,
    activeSegment,
    segmentsLoading,
    handleSelectedSegment,
    SegmentsError,
  } = useSelectSegment();

  const { handleSave, saveError } = useSaveAsDraft(title);

  const handleNextStep = async () => {
    setNextLoad(true);
    const { id: savedID } = await handleSave();
    if (savedID) {
      setCampaignId(savedID);
      dispatch(setCampaignName(title));
      if (!id) {
        navigate(`/campaigns/create-campaign/${savedID}?step=2`);
      } else {
        navigate('?step=2');
      }
      dispatch(setNextStep());
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
    setNextLoad(false);
  };

  const handleChangeHypothesisNote = (text: string) => {
    dispatch(setCampaignHypothesis(text));
  };

  const handleSaveAsDraft = async () => {
    dispatch(setGlobalLoading(true));
    const campaign = await handleSave();
    if (campaign.id && !saveError) {
      dispatch(setGlobalLoading(false));
      customToastMessage(
        `Campaign successfully ${campaignID || id ? 'updated' : 'created'}.`,
        <HiCheckCircle className="h-5 w-5" />,
        'success'
      );
    }
    if (isUpdateCampaign) {
      dispatch(setGlobalLoading(false));
      navigate('/campaigns');
    }
  };

  useEffect(() => {
    if (googleAdsError || googleCampaignError || SegmentsError) {
      customToastMessage(
        googleAdsError || googleCampaignError || SegmentsError,
        <HiExclamation className="h-5 w-5" />,
        'error'
      );
    }
    if (saveError) {
      const { errorMessage } = parseError(saveError);
      customToastMessage(
        errorMessage,
        <HiExclamation className="h-5 w-5" />,
        'error'
      );
      dispatch(setGlobalLoading(false));
    }
  }, [saveError, googleAdsError, googleCampaignError, SegmentsError, dispatch]);

  useEffect(() => {
    setTitle(campaignName);
  }, [campaignName]);

  return (
    <div className="flex-1 flex flex-col items-start justify-between mt-12">
      <div className="flex flex-col gap-8 min-w-[40%]">
        <Input
          id="company-name"
          title="Personalization Campaign Title"
          value={title || campaignName}
          placeholder="My personalization campaign"
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setTitle(e.target.value)
          }
        />
        <div className="max-w-[364px]">
          <SimpleSelect
            title="Traffic Source"
            items={TRAFFIC_SOURCE}
            value={TRAFFIC_SOURCE[2].label}
            onSelect={() => null}
            itemClassName="[&]:text-sm [&]:font-normal text-gray-700"
          />
        </div>
        <div className="max-w-[364px]">
          <SimpleSelect
            title="Segment"
            items={segments}
            value={activeSegment?.label || 'Select segment'}
            onSelect={(selectedID) => handleSelectedSegment(selectedID)}
            itemClassName="[&]:text-sm [&]:font-normal text-gray-700"
            loading={segmentsLoading}
            disabled={segments.length <= 0}
          />
        </div>
        <div className="max-w-[364px]">
          <SimpleSelect
            title="Google Ads Account"
            items={accounts || []}
            value={activeAccount?.label || 'Select account'}
            onSelect={(selectedID) => handleSelectedAccount(selectedID)}
            itemClassName="[&]:text-sm [&]:font-normal [&]:text-gray-700"
          />
        </div>
        <div className="max-w-[364px]">
          <GroupSelect
            title="Select Google Ads Campaign"
            items={campaigns || []}
            value={activeCampaign?.label || 'Select campaign'}
            onSelect={(selectedID) => handleSelectedCampaign(selectedID)}
            itemClassName="[&]:text-sm [&]:font-normal [&]:text-gray-700"
            disabled={!activeAccount || campaignsLoading}
            loading={campaignsLoading}
          />
        </div>
        <div>
          <p className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Note
          </p>
          <Textarea
            id="comment"
            className="max-w-[494px] resize-none bg-white"
            value={hypothesis_description}
            placeholder="Write text here ..."
            required={false}
            rows={3}
            onChange={(e) => handleChangeHypothesisNote(e.target.value)}
          />
          <p className="mt-3 text-sm font-normal text-gray-500">
            A note for hypothesis, goals, metrics & etc.
          </p>
        </div>
      </div>
      <CreateCampaignFooter
        handleNextStep={() => handleNextStep()}
        disabledNextButton={!activeAccount || !activeCampaign || title === ''}
        onDraftSave={handleSaveAsDraft}
        nextButtonLoading={nextLoad}
        saveTitle={isUpdateCampaign ? 'Save and Exit' : undefined}
      />
    </div>
  );
};

export default StepOne;
