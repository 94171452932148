import { ResponseWrap } from '../../response_wrap';
import { authPageAPI } from '..';
import { AuthRequest, AuthResponse } from '../types';

export const authAPI = authPageAPI.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<ResponseWrap<AuthResponse>, AuthRequest>({
      query: (credentials) => ({
        url: 'api/auth',
        credentials: 'include',
        method: 'POST',
        body: credentials,
      }),
    }),
    signup: builder.query<void, { email: string }>({
      query: (email) => ({
        url: 'api/otp/create',
        method: 'POST',
        body: email,
      }),
    }),
    logoutQuery: builder.mutation<{ message: string }, void>({
      query: () => ({
        url: 'api/auth',
        credentials: 'include',
        method: 'DELETE',
      }),
    }),
    protected: builder.mutation<{ message: string }, void>({
      query: () => 'protected',
    }),
  }),
});

export const {
  useLoginMutation,
  useSignupQuery,
  useLazySignupQuery,
  useLogoutQueryMutation,
} = authAPI;
