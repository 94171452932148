import { Navigate } from 'react-router-dom';
import Layout from 'src/features/base-layout/layout';
import { useAppSelector } from 'src/store/hooks';

const ProtectedRoute = () => {
  const { isAuthenticated } = useAppSelector((state) => state.authReducer);

  if (isAuthenticated === false) {
    return <Navigate to="/login" replace />;
  }
  return <Layout />;
};
export default ProtectedRoute;
