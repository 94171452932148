import {
  BaseQueryApi,
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  fetchBaseQuery,
} from '@reduxjs/toolkit/dist/query/react';
import { SerializedError } from '@reduxjs/toolkit';
import { RootState } from '..';

const BACKEND_BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;

export interface IParsedError {
  error: ParsedError;
  errorMessage: string;
  errorCode: number | string;
}

export const bq = fetchBaseQuery({
  baseUrl: BACKEND_BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).authReducer.access_token;
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }
    return headers;
  },
}) as BaseQueryFn<
  string | FetchArgs,
  any,
  FetchBaseQueryError,
  any,
  FetchBaseQueryMeta
>;

export const baseQuery = async (
  ...args: [args: any, api: BaseQueryApi, extraOptions: any]
) => {
  const result = await bq(...args);

  if ('error' in result && result.error) {
    return { error: result.error };
  }

  return { data: result.data };
};

export const refreshQuery = fetchBaseQuery({
  baseUrl: BACKEND_BASE_URL,
  credentials: 'include',
});

export const parseError = (error: ParsedError): IParsedError => {
  const defaultErrorMessage = 'Server connection error';
  const newError: IParsedError = {
    error: error || {},
    errorMessage: defaultErrorMessage,
    errorCode: 'UNKNOWN',
  };

  if (!error) {
    newError.errorMessage = '';
    return newError;
  }

  if ('status' in error) {
    newError.errorCode = error.status;

    if (error.status === 422 && 'data' in error) {
      const errorData = error.data as Record<string, any>;
      newError.errorMessage =
        errorData?.message ||
        errorData?.errors?.join(', ') ||
        defaultErrorMessage;
      newError.error = errorData;
      return newError;
    }

    if (error.status === 400 && 'data' in error) {
      const errorData = error.data as Record<string, any>;
      newError.errorMessage =
        errorData?.message ||
        errorData?.errors?.join(', ') ||
        defaultErrorMessage;
      newError.error = errorData;
      return newError;
    }

    if ('data' in error) {
      const errorData = error.data as Record<string, any>;
      newError.errorMessage =
        errorData?.message ||
        errorData?.error ||
        `Error ${error.status}` ||
        defaultErrorMessage;
      newError.error = error;
      return newError;
    }
  }

  if ('message' in error && error.name !== 'AbortError') {
    newError.errorCode = 'SERIALIZED_ERROR';
    newError.errorMessage = error.message || defaultErrorMessage;
    newError.error = error;
    return newError;
  }

  if ('name' in error && error.name === 'AbortError') {
    newError.errorCode = 'UNKNOWN';
    newError.errorMessage = defaultErrorMessage;
    newError.error = {};
    return newError;
  }

  return newError;
};

export const parseResponse = <T>(
  response:
    | {
        data: T;
      }
    | {
        error: FetchBaseQueryError | SerializedError;
      }
) => {
  const error = 'error' in response && parseError(response.error);
  const result = 'data' in response && response.data;
  let errorMessage = undefined;
  const isEmptyError = JSON.stringify(error ? error.error : {}) === '{}';

  if (!result && !isEmptyError) {
    if (error) {
      errorMessage = error.errorMessage;
    }
  }
  return { result, errorMessage, error };
};

export type ParsedError = FetchBaseQueryError | SerializedError | undefined;
export class BadRequestError {
  error?: string;
  message?: string;
  statusCode?: number;
}

export class UnprocessableEntityError {
  error?: string;
  message?: UnprocessableEntityField[];
  statusCode?: number;
}

export class UnprocessableEntityField {
  error?: string;
  field?: string;
}
