import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'src/store/hooks';
import { setClearCampaignData } from 'src/store/slices/campaignSlice';
import { setClearCampaignsStep } from 'src/store/slices/storageSlice';
import { resetEditorState } from 'src/store/slices/markup-editor-slice';

type CrumbsItem = {
  title: string;
  link: string;
};

interface IBreadCrumbs {
  items: CrumbsItem[];
}

const BreadCrumbs = ({ items }: IBreadCrumbs) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handlePageNavigate = (href: string, e: any, index: number) => {
    e.preventDefault();
    if (index !== items.length - 1) {
      dispatch(setClearCampaignData());
      dispatch(setClearCampaignsStep());
      dispatch(resetEditorState());
      navigate(href);
    }
  };

  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="inline-flex items-center">
        {items.map((crumb, index) => (
          <li key={`${crumb}-${index}`} className="inline-flex items-center">
            <button
              className={`inline-flex items-center text-sm font-medium text-gray-500 ${
                index === items.length - 1
                  ? 'cursor-default'
                  : 'hover:text-blue-700'
              }`}
              type="button"
              onClick={(e) => handlePageNavigate(crumb.link, e, index)}
            >
              {crumb.title}
            </button>
            {index !== items.length - 1 && (
              <svg
                stroke="currentColor"
                fill="none"
                strokeWidth="2"
                viewBox="0 0 24 24"
                aria-hidden="true"
                className="mx-2 h-4 w-4 text-gray-400 group-first:hidden"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 5l7 7-7 7"
                ></path>
              </svg>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default BreadCrumbs;
