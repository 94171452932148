import React, { useCallback, useState, useRef, useEffect } from 'react';
import { createPortal } from 'react-dom';
import OutsideClickHandler from 'src/helpers/outside-click';
import { DropDownArrow } from '../icons';
import Spinner from '../base-spinner';

interface ISelect {
  ItemList: (handleClose: (e: any) => void) => JSX.Element;
  title?: string;
  type?: 'base' | 'text';
  value?: string;
  placeholder?: string;
  baseClassName?: string;
  inputClassName?: string;
  popupClassName?: string;
  disabled?: boolean;
  isLoad?: boolean;
  errorMessage?: string;
  reverse?: boolean;
}

const BaseSelect = ({
  type = 'base',
  title,
  value,
  placeholder,
  baseClassName,
  inputClassName,
  popupClassName,
  disabled,
  isLoad,
  reverse,
  ItemList,
}: ISelect) => {
  const [selectOpen, setSelectOpen] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({
    top: 0,
    left: 0,
    width: 0,
  });
  const selectRef = useRef<HTMLDivElement | null>(null);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const handleSelectOpen = useCallback(
    (e: any) => {
      e.preventDefault();
      if (!disabled && selectRef.current) {
        const rect = selectRef.current.getBoundingClientRect();
        setDropdownPosition({
          top: rect.bottom,
          left: rect.left,
          width: rect.width,
        });
        setSelectOpen(!selectOpen);
      }
    },
    [disabled, selectOpen]
  );

  const handleClose = useCallback((e: any) => {
    e.stopPropagation();
    setSelectOpen(false);
  }, []);

  const handleScroll = useCallback((e: Event) => {
    if (
      e.target instanceof Node &&
      dropdownRef.current &&
      !dropdownRef.current.contains(e.target as Node) &&
      selectRef.current &&
      !selectRef.current.contains(e.target as Node)
    ) {
      setSelectOpen(false);
    }
  }, []);

  useEffect(() => {
    if (selectOpen) {
      document.addEventListener('scroll', handleScroll, true);
      window.addEventListener('resize', handleScroll);
    }

    return () => {
      document.removeEventListener('scroll', handleScroll, true);
      window.removeEventListener('resize', handleScroll);
    };
  }, [selectOpen, handleScroll]);

  const DropdownMenu = ({ children }: { children: React.ReactNode }) =>
    createPortal(
      <div
        ref={dropdownRef}
        style={{
          position: 'fixed',
          top:
            type === 'base' ? dropdownPosition.top - 8 : dropdownPosition.top,
          left: dropdownPosition.left,
          width: dropdownPosition.width,
          zIndex: 9999,
        }}
        className={`${
          type === 'base' ? 'top-[calc(100%_-_5px)]' : 'top-full'
        } left-0 right-0 p-3 ${type === 'base' ? 'pt-5' : ''} ${
          type === 'base'
            ? 'max-h-[154px] max-w-max'
            : 'min-w-[210px] max-h-[154px] max-w-max'
        } border ${type === 'base' ? 'border-t-0' : ''} border-gray-300 ${
          type === 'base' ? 'rounded-b-lg' : 'rounded-lg'
        } bg-white shadow-md overflow-y-auto z-[60] ${popupClassName}`}
        role="listbox"
      >
        {children}
      </div>,
      selectRef.current || document.body
    );

  return (
    <OutsideClickHandler onOutsideClick={() => setSelectOpen(false)}>
      <div
        ref={selectRef}
        className={`block relative ${
          disabled ? 'pointer-events-none' : 'cursor-pointer'
        } ${baseClassName}`}
        onClick={handleSelectOpen}
        role="combobox"
        aria-expanded={selectOpen ? 'true' : 'false'}
        aria-haspopup="listbox"
        aria-controls="selectDropdown"
      >
        {type === 'base' ? (
          <>
            {title && (
              <label
                htmlFor="selectInput"
                className="block mb-2 text-sm font-medium text-gray-900 text-left"
              >
                {title}
              </label>
            )}
            <div
              className={`relative w-full pointer-events-none ${
                disabled ? 'opacity-60' : ''
              }`}
              tabIndex={0}
            >
              <input
                id="selectInput"
                type="text"
                readOnly
                value={placeholder || value || ''}
                className={`${
                  reverse ? 'pl-9' : ''
                } py-2 px-4 w-full min-h-[37px] bg-white text-sm font-normal text-gray-900 border border-gray-300 rounded-lg ${inputClassName}`}
                tabIndex={-1}
                aria-disabled={disabled ? 'true' : 'false'}
                aria-readonly="true"
                aria-placeholder={placeholder || ''}
                aria-label={title || ''}
              />
              {isLoad ? (
                <div className="absolute right-2.5 top-1/2 -translate-y-[55%]">
                  <Spinner size="h-3 w-3" />
                </div>
              ) : (
                <DropDownArrow
                  className={`absolute ${
                    reverse ? 'left-2.5' : 'right-2.5'
                  } top-1/2 -translate-y-2/4`}
                />
              )}
            </div>
          </>
        ) : (
          <p className="leading-tight font-medium border-0 bg-inherit text-xs text-blue-600 text-left">
            {title ? title : ''}
            {isLoad && <Spinner className="ml-1" size="h-3 w-3" />}
          </p>
        )}
        {selectOpen && <DropdownMenu>{ItemList(handleClose)}</DropdownMenu>}
      </div>
    </OutsideClickHandler>
  );
};

export default React.memo(BaseSelect);
