import { useEffect, useState } from 'react';
import { HiCheckCircle, HiExclamation } from 'react-icons/hi';
import {
  useGetGAConfigQuery,
  useStoreGoogleAnalyticsConfigMutation,
} from 'src/store/services/google/endpoints/google';

import { customToastMessage } from 'src/components/toast';
import Button from 'src/components/button';
import ModalBox from 'src/components/modal-box/modal-box';
import Input from 'src/components/input';

interface IGAModalProp {
  showModal: boolean;
  handleHideModal: () => void;
  setGaConfigLoading: (isLoading: boolean) => void;
}

const GAModal = ({
  showModal,
  handleHideModal,
  setGaConfigLoading,
}: IGAModalProp) => {
  const [apiSecret, setApiSecret] = useState('');
  const [measurementId, setMeasurementId] = useState('');

  const [storeGoogleAnalyticsConfig, { isLoading: isSavingGAConfig }] =
    useStoreGoogleAnalyticsConfigMutation();

  const { data: remoteGAConfig, isLoading: isLoadingRemoteGAConfig } =
    useGetGAConfigQuery();

  const handleSaveConfigure = async () => {
    if (isSavingGAConfig) return;
    try {
      const data = await storeGoogleAnalyticsConfig({
        api_secret: apiSecret,
        measurement_id: measurementId,
      }).unwrap();

      if (data && data.message) {
        customToastMessage(
          data.message,
          <HiCheckCircle className="h-5 w-5" />,
          'success'
        );
      }
    } catch (error: any) {
      console.log(error);
      if (
        error.data &&
        error.data.statusCode &&
        error.data.statusCode === 400
      ) {
        customToastMessage(
          error.data.message,
          <HiExclamation className="h-5 w-5" />,
          'error'
        );
      }
    }
  };

  useEffect(() => {
    if (remoteGAConfig?.data.measurement_id) {
      setMeasurementId(remoteGAConfig.data.measurement_id);
    }
    if (remoteGAConfig?.data.measurement_id) {
      setApiSecret(remoteGAConfig.data.api_secret);
    }
    // eslint-disable-next-line
  }, [remoteGAConfig]);

  useEffect(() => {
    setGaConfigLoading(isLoadingRemoteGAConfig);
  }, [isLoadingRemoteGAConfig, setGaConfigLoading]);

  return (
    <ModalBox
      isOpen={showModal}
      windowClassName="max-w-4xl"
      onClose={handleHideModal}
    >
      <ModalBox.Header onClose={handleHideModal}>
        Configure your Google Analytics
      </ModalBox.Header>
      <ModalBox.Body>
        <div className="flex flex-col gap-y-[16px]">
          <div>
            <Input
              id="measurement-id"
              title="Measurement id"
              className="[&>div>div]:max-w-none [&>div>div>input]:max-w-none"
              placeholder={measurementId}
              onChange={(e) => setMeasurementId(e.target.value)}
              value={measurementId}
            />
          </div>
          <div>
            <Input
              id="api-secret"
              title="API secret"
              className="[&>div>div]:max-w-none [&>div>div>input]:max-w-none"
              placeholder={apiSecret}
              onChange={(e) => setApiSecret(e.target.value)}
              value={apiSecret}
            />
          </div>
        </div>
      </ModalBox.Body>
      <ModalBox.Footer>
        <Button
          onClick={handleSaveConfigure}
          title="Save"
          isLoading={isSavingGAConfig}
        />
      </ModalBox.Footer>
    </ModalBox>
  );
};

export default GAModal;
