import { useState, useCallback, useRef } from 'react';
import { differenceInDays } from 'date-fns';
import { useLazyGetChartDataQuery } from 'src/store/services/create-campaign/endpoints/create-campaign';
import {
  ChartData,
  TStatistic,
} from 'src/store/services/create-campaign/types';
import { parseResponse } from 'src/store/services/helpers';
import { showToast } from 'src/features/campaigns/blocks/sync-access-modal';

const useChartData = (
  part: string,
  filterParams: any,
  lastDay: string,
  toDay: string
) => {
  const [getChartData] = useLazyGetChartDataQuery();
  const [chart, setChart] = useState<ChartData | null>(null);
  const [total, setTotal] = useState<TStatistic | undefined>();
  const [chartLoad, setChartLoad] = useState(true);
  const previousRequest = useRef<any>(null);

  const loadChartData = useCallback(
    async (campaignID: string, type?: string) => {
      setChartLoad(true);
      if (previousRequest.current) {
        previousRequest.current.abort();
      }
      const differentDays = differenceInDays(
        new Date(lastDay),
        new Date(toDay)
      );

      const filterPart =
        (filterParams.part || part || type) === 'day' && differentDays > -3
          ? 'hour'
          : filterParams.part;

      try {
        const request = getChartData({
          campaignID,
          filter: {
            startDate: lastDay,
            endDate: toDay,
            part: filterPart,
            google_ad_ids: filterParams.ads || '',
            conversion_action_ids: filterParams.conversions || '',
            is_desktop: filterParams.is_desktop || '',
          },
        });
        previousRequest.current = request;

        const { data, error } = await request;
        const { result: chartResult, errorMessage: chartErrorMessage } =
          parseResponse({ data, error });

        if (chartErrorMessage) {
          throw new Error(chartErrorMessage || 'An error occurred');
        }

        if (chartResult) {
          setChart(chartResult);
          setTotal(chartResult.reportByAds.total);
        }
      } catch (error: any) {
        showToast(error.message, 'error');
      } finally {
        setTimeout(() => setChartLoad(false), 500);
        previousRequest.current = null;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [part, filterParams.part, getChartData, lastDay, toDay]
  );

  return { chart, total, chartLoad, loadChartData };
};

export default useChartData;
