import React, { useEffect, useMemo } from 'react';
import useSegmentChild from '../hooks/useSegmentChild';
import TagInput, { TItem } from './tag-input';
import Autocomplete from './autocomplete';
import GroupCheckbox, { TGroupItem } from './group-checkbox';
import { TAG_VALUES } from '../constants';
import { Trush } from 'src/components/icons';

interface ISegmentSelect {
  firstTitle: string;
  segmentID: string;
  selectedChildes?: TItem[];
  savedSegmentIDs: (value: string[], firstTitle: string) => void;
  onDeleteAttributeClick: (segmentID: string) => void;
}

const SegmentSection = ({
  firstTitle,
  segmentID,
  selectedChildes,
  savedSegmentIDs,
  onDeleteAttributeClick,
}: ISegmentSelect) => {
  const shortsAttribute = TAG_VALUES[firstTitle as keyof typeof TAG_VALUES];
  const { segmentAttributes } = useSegmentChild(segmentID);
  const [selectItems, setSelectItems] = React.useState<TItem[]>(
    selectedChildes ? selectedChildes : []
  );

  const handleDeleteElement = (value: string) => {
    const newArr = selectItems.filter((item) => item.item !== value);
    setSelectItems(newArr);
  };

  const handleSelectItem = (items: TGroupItem[]) => {
    const parentItems: TGroupItem[] = [];
    const childItems: TGroupItem[] = [];

    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      const isParent = item?.child;
      if (isParent) {
        childItems.push(item);
      } else {
        parentItems.push(item);
      }
    }

    const filtered = parentItems.filter((item) => {
      return !childItems.find((it) => item.parentID === it.value);
    });

    const resultObject: { [key: string]: any } = childItems
      .concat(filtered)
      .reduce((a, d) => {
        return {
          ...a,
          [d.value]: { item: d.name, value: d.value, parentValue: d.parentID },
        };
      }, {});

    selectItems.forEach((item) => {
      if (
        resultObject[item.value] ||
        (item.parentValue && resultObject[item.parentValue])
      ) {
        delete resultObject[item.value];
        return;
      }

      resultObject[item.value] = { ...item };
    });

    setSelectItems(Object.values(resultObject));
  };

  const getItemsWithChildren = useMemo(() => {
    const result: TGroupItem[] = [];

    const stack = segmentAttributes.slice();

    const resultObject: { [key: string]: any } = selectItems.reduce((a, d) => {
      return { ...a, [d.value]: { item: d.item, value: d.value } };
    }, {});

    while (stack.length > 0) {
      const item = stack.pop();
      if (resultObject[item.value]) {
        result.push(item);
        if (item.child) {
          result.push(...item.child);
        }
      } else if (item.child) {
        stack.push(...item.child);
      }
    }

    return result;
  }, [segmentAttributes, selectItems]);

  useEffect(() => {
    const selectedIDs = selectItems.map((item) => item.value);
    savedSegmentIDs(selectedIDs, firstTitle);
  }, [firstTitle, savedSegmentIDs, selectItems]);

  useEffect(() => {
    if (selectedChildes) {
      setSelectItems(selectedChildes);
    }
  }, [selectedChildes, firstTitle]);

  return (
    <div className="flex w-full mb-4 [&>div]:w-full">
      <TagInput
        selectedElements={selectItems}
        onDeleteElement={handleDeleteElement}
        placeholder={`Select attribute from ${firstTitle}`}
        ItemList={() =>
          shortsAttribute ? (
            <GroupCheckbox
              items={segmentAttributes}
              onHandleChecked={handleSelectItem}
              selectedItems={getItemsWithChildren}
            />
          ) : (
            <Autocomplete
              items={segmentAttributes}
              selectedItems={getItemsWithChildren}
              firstTitle={firstTitle}
              onHandleSelect={(items) => {
                handleSelectItem(items);
              }}
            />
          )
        }
      />
      <button
        className="ml-3"
        type="button"
        onClick={() => {
          savedSegmentIDs([], firstTitle);
          onDeleteAttributeClick(segmentID);
        }}
      >
        <Trush />
      </button>
    </div>
  );
};

export default SegmentSection;
