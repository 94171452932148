import { useEffect, useMemo, useState } from 'react';
import { SelectOption } from 'src/components/selects/simple-select';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { parseError } from 'src/store/services/helpers';
import { useGetAllSegmentsQuery } from 'src/store/services/segments/endpoints/segments';
import { setSegmentID } from 'src/store/slices/campaignSlice';

const useSelectSegment = () => {
  const dispatch = useAppDispatch();
  const { segmentID } = useAppSelector((state) => state.createCampaignReducer);
  const { isLoading, data, error } = useGetAllSegmentsQuery();

  const selectedSegment = useMemo(
    () => data?.data.find((item) => item.segments_id === segmentID),
    [data, segmentID]
  );
  const [selectedValue, setSelectedValue] = useState<SelectOption | undefined>(
    undefined
  );

  const segments = useMemo(() => {
    return data
      ? data.data.map((account) => ({
          label: account.segments_name,
          value: account.segments_id,
        }))
      : [];
  }, [data]);

  const handleSelectedSegment = (selectedID: string) => {
    const selectedAccount = segments?.find((item) => item.value === selectedID);
    if (selectedAccount) {
      setSelectedValue(selectedAccount);
      dispatch(setSegmentID(selectedAccount.value));
    } else {
      setSelectedValue(undefined);
      dispatch(setSegmentID(''));
    }
  };

  useEffect(() => {
    if (selectedSegment && !selectedValue) {
      setSelectedValue({
        label: selectedSegment?.segments_name,
        value: selectedSegment?.segments_id,
      });
      dispatch(setSegmentID(selectedSegment.segments_id));
    }
  }, [dispatch, selectedSegment, selectedValue]);

  if (error) {
    const { errorMessage } = parseError(error);
    return {
      segments: [{ label: '', value: '' }],
      activeSegment: { label: '', value: '' },
      segmentsLoading: isLoading,
      handleSelectedSegment: () => null,
      SegmentsError: errorMessage,
    };
  }

  return {
    segments,
    activeSegment: selectedValue,
    segmentsLoading: isLoading,
    handleSelectedSegment,
    SegmentsError: '',
  };
};

export default useSelectSegment;
