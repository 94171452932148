import { useState, useCallback } from 'react';
import { showToast } from 'src/features/campaigns/blocks/sync-access-modal';
import { useLazyGetCampaignByIdQuery } from 'src/store/services/create-campaign/endpoints/create-campaign';
import {
  ChartData,
  DetailCampaignAd,
  IUserCampaign,
} from 'src/store/services/create-campaign/types';
import { parseResponse } from 'src/store/services/helpers';

const useCampaignData = (lastDay: string, toDay: string, version: string) => {
  const [getCampaignById] = useLazyGetCampaignByIdQuery();
  const [campaign, setCampaign] = useState<IUserCampaign | undefined>();
  const [emptyCampaign, setEmptyCampaign] = useState<
    { id: string; name: string } | undefined
  >();
  const [ads, setAds] = useState<DetailCampaignAd[]>([]);
  const [campaignLoad, setCampaignLoad] = useState(true);

  const loadCampaignData = useCallback(
    async (campaignID: string) => {
      setCampaignLoad(true);
      try {
        const { data: campaignData, error: campaignError } =
          await getCampaignById({
            campaignID,
            filter: { startDate: lastDay, endDate: toDay, version },
          });

        const { result: campaignResult, errorMessage: campaignErrorMessage } =
          parseResponse({ data: campaignData, error: campaignError });

        if (campaignErrorMessage) throw new Error(campaignErrorMessage);

        if (campaignResult) {
          if (campaignResult.data.ads.length > 0) {
            setCampaign(campaignResult.data.ads[0].campaign.user_campaign);
            setAds(campaignResult.data.ads);
          }
          setEmptyCampaign(campaignResult.data.campaign);
        }
      } catch (error: any) {
        showToast(error.message, 'error');
      } finally {
        setCampaignLoad(false);
      }
    },
    [getCampaignById, lastDay, toDay, version]
  );

  const handleAddAdsStatistics = useCallback(
    (chart: ChartData) => {
      if (ads.length > 0 && chart && chart.reportByAds) {
        const adsArrWithStat = ads.map((ad) => {
          if (chart.reportByAds[ad.id]) {
            return {
              ...ad,
              experimental_leads:
                chart.reportByAds[ad.id].experimental_conversions || 0,
              experimental_visitors:
                chart.reportByAds[ad.id].experimental_visitors || 0,
              full_leads: chart.reportByAds[ad.id].ctrl_conversions || 0,
              full_visitors: chart.reportByAds[ad.id].ctrl_visitors || 0,
            };
          } else {
            return ad;
          }
        });
        setAds(adsArrWithStat);
      }
    },
    [ads]
  );

  return {
    campaign,
    emptyCampaign,
    ads,
    campaignLoad,
    loadCampaignData,
    handleAddAdsStatistics,
  };
};

export default useCampaignData;
