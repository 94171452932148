import { useState } from 'react';

import Input from 'src/components/input';
import Button from 'src/components/button';
import ModalBox from 'src/components/modal-box/modal-box';

interface IModalProps {
  isOpen: boolean;
  variable: string;
  onClose: () => void;
  onSave: (value: string) => void;
}

const FallbackValueModal = ({
  isOpen,
  onClose,
  onSave,
  variable,
}: IModalProps) => {
  const [value, setValue] = useState('');

  const handleSave = () => {
    onSave(value);
    setValue('');
  };

  return (
    <ModalBox isOpen={isOpen} onClose={onClose} windowClassName="max-w-xl">
      <ModalBox.Header>
        <p className="text-lg font-semibold text-gray-900">Fallback value</p>
      </ModalBox.Header>
      <ModalBox.Body>
        Please enter a fallback value for {variable}. This will be used in cases
        where Clearbit doesn&apos;t identify the company of the website visitor.
        <Input
          id="attribute-name"
          placeholder="Fallback value"
          className="mt-4 [&>div>div]:max-w-[unset] [&>div>div>input]:max-w-[unset]"
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      </ModalBox.Body>
      <ModalBox.Footer className="border-t-0 pt-0">
        <div className="flex justify-between">
          <Button title="Save" type="default" onClick={handleSave} />
          <Button
            title="Cancel"
            className="[&]:mr-0"
            type="outline"
            onClick={onClose}
          />
        </div>
      </ModalBox.Footer>
    </ModalBox>
  );
};

export default FallbackValueModal;
