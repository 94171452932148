interface IBaseCheckbox {
  id: string;
  checked: boolean;
  title?: string;
  onHandleCheck: (e?: any) => void;
}

const BaseCheckbox = ({ id, checked, title, onHandleCheck }: IBaseCheckbox) => {
  return (
    <div className="flex gap-2 items-center">
      <input
        id={id}
        type="checkbox"
        checked={checked}
        onChange={onHandleCheck}
        className={`${
          title && 'mr-1'
        } h-4 w-4 rounded border border-gray-300 bg-gray-100 focus:ring-2 focus:ring-cyan-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-cyan-600 text-cyan-600 cursor-pointer`}
      />
      {title && (
        <label
          htmlFor={id}
          className="text-sm font-medium text-gray-900 dark:text-gray-300"
        >
          {title}
        </label>
      )}
    </div>
  );
};

export default BaseCheckbox;
