import { FC, ReactElement } from 'react';
import {
  HiTrendingUp,
  HiUsers,
  HiCursorClick,
  HiCash,
  HiChevronRight,
} from 'react-icons/hi';
import { useParams } from 'react-router-dom';
import { calcCR, calcCRLift } from 'src/helpers/cdf';
import { DetailCampaignAd } from 'src/store/services/create-campaign/types';
import VersionPicker from './version-picker';

interface CampaignsOverview {
  visitors?: number;
  experimental_visitors?: number;
  leads?: number;
  experimental_leads?: number;
  isSignificant?: boolean;
  onChangeDate: (startDate: Date, endDate: Date) => void;
  onChangeVersion: (version: string) => void;
  onChangeAds: (adID: string[]) => void;
  onChangeConversion: (conversion: string) => void;
  onChangeDevice: (deviceValue: string) => void;
  onChangeInterval: (value: string) => void;
  show?: boolean;
  lastCampaignDate: Date;
  ads?: DetailCampaignAd[];
  isLoading?: boolean;
}

const CampaignsOverviewComponent: FC<CampaignsOverview> = function ({
  experimental_visitors,
  visitors,
  leads,
  experimental_leads,
  isSignificant,
  onChangeDate,
  onChangeVersion,
  onChangeAds,
  onChangeConversion,
  onChangeDevice,
  onChangeInterval,
  show,
  lastCampaignDate,
  ads,
  isLoading,
}) {
  const { id } = useParams();
  if (!show) {
    return <></>;
  }
  const crLift = calcCRLift(
    visitors,
    experimental_visitors,
    leads,
    experimental_leads
  );

  return (
    <div className="flex flex-col gap-y-4 relative">
      <div className="flex items-center justify-end">
        <VersionPicker
          allPages={!id}
          campaignID={id || ''}
          onChangeDate={onChangeDate}
          onChangeVersion={onChangeVersion}
          onChangeAds={onChangeAds}
          onChangeDevice={onChangeDevice}
          onChangeConversions={onChangeConversion}
          onChangeTimeInterval={onChangeInterval}
          lastCampaignDate={lastCampaignDate}
          ads={ads || ([] as DetailCampaignAd[])}
          disabled={isLoading}
        />
      </div>
      <div className="flex">
        {isLoading ? (
          <div className="w-[270px] mr-10 flex h-full rounded-lg bg-white shadow-sm border-0 overflow-hidden items-center gap-y-[5px] flex-col px-8 py-5">
            <div className="flex flex-col mb-1 gap-y-[2px] w-full animate-pulse">
              <div className="mb-1 w-6 h-6 bg-gray-200 rounded-full"></div>
              <div className="w-[125px] h-5 bg-gray-200 rounded"></div>
            </div>
            <div className="flex flex-col gap-y-[2px] w-full animate-pulse">
              <div className="mb-1 w-2/4 h-6 bg-gray-200 rounded"></div>
              <div className="w-[125px] h-4 bg-gray-200 rounded"></div>
            </div>
          </div>
        ) : (
          <div className="w-[270px] mr-10">
            <div className="flex h-full rounded-lg bg-white shadow-sm border-0 w-full overflow-hidden items-center gap-y-[5px] flex-col px-8 py-5">
              <div className="flex flex-col w-full gap-y-[2px] leading-tight text-base font-normal text-gray-500">
                <HiTrendingUp className="text-gray-400" size={24} />
                <span>Conversion Lift</span>
              </div>
              <div className="flex flex-col w-full gap-y-[2px]">
                <span
                  className={`text-2xl font-extrabold ${
                    crLift.includes('-') ? 'text-gray-500' : 'text-green-500'
                  }`}
                >
                  {crLift}
                </span>

                <span className="leading-tight text-sm font-normal text-gray-500 whitespace-nowrap">
                  {isSignificant && 'Stat sig'}
                </span>
              </div>
            </div>
          </div>
        )}
        <div className="w-full">
          <div className="flex rounded-lg bg-white shadow-sm border-0 w-full overflow-hidden items-center gap-y-[5px] justify-between px-8 py-5">
            {isLoading ? (
              <StatBlockSkeleton />
            ) : (
              <StatBlock
                icon={<HiUsers size={24} />}
                name="Visitors"
                all={experimental_visitors}
                control={visitors}
              />
            )}
            <HiChevronRight size={30} className="text-gray-400" />
            {isLoading ? (
              <StatBlockSkeleton />
            ) : (
              <StatBlock
                icon={<HiCursorClick size={24} />}
                name="Conversion Rate"
                all={
                  calcCR(experimental_visitors ?? 0, experimental_leads ?? 0) +
                  '%'
                }
                control={calcCR(visitors ?? 0, leads ?? 0) + '%'}
              />
            )}
            <HiChevronRight size={30} className="text-gray-400" />
            {isLoading ? (
              <StatBlockSkeleton />
            ) : (
              <StatBlock
                icon={<HiCash size={24} />}
                name="Conversions"
                all={experimental_leads}
                control={leads}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

interface StatData {
  icon: ReactElement;
  name: string;
  all?: number | string;
  control?: number | string;
}

const StatBlock: FC<StatData> = function ({ icon, name, all, control }) {
  return (
    <div className="flex gap-y-[5px] flex-col">
      <div className="flex flex-col w-full gap-y-[2px] leading-tight text-base font-normal text-gray-500">
        {icon}
        <span>{name}</span>
      </div>
      <div className="flex flex-col w-full gap-y-[2px]">
        <span className="text-2xl font-semibold text-gray-900">{all}</span>
        <span className="leading-tight text-sm font-normal text-gray-500">
          vs. {control} control
        </span>
      </div>
    </div>
  );
};

const StatBlockSkeleton = () => {
  return (
    <div className="flex gap-y-[5px] flex-col animate-pulse">
      <div className="flex flex-col mb-1 w-full gap-y-[2px]">
        <div className="mb-1 w-6 h-6 bg-gray-300 rounded-full"></div>
        <div className="w-[125px] h-5 bg-gray-300 rounded"></div>
      </div>
      <div className="flex flex-col w-full gap-y-[2px]">
        <div className="mb-1 w-2/4 h-6 bg-gray-300 rounded"></div>
        <div className="w-[125px] h-4 bg-gray-300 rounded"></div>
      </div>
    </div>
  );
};

export default CampaignsOverviewComponent;
