import { useEffect, useMemo, useState } from 'react';

import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { useGetGoogleAdsQuery } from 'src/store/services/create-campaign/endpoints/create-campaign';
import { setSelectedGoogleAds } from 'src/store/slices/campaignSlice';
import { parseError } from 'src/store/services/helpers';
import { SelectOption } from 'src/components/selects/simple-select';

interface IProps {
  handleSelectedCampaign: (value: string) => void;
}

const useSelectAccountGoogleADS = ({ handleSelectedCampaign }: IProps) => {
  const dispatch = useAppDispatch();
  const { googleAdsAccount } = useAppSelector(
    (state) => state.createCampaignReducer
  );
  const { data, isLoading, error } = useGetGoogleAdsQuery();

  const selectedAccount = useMemo(
    () => data?.data.find((item) => item.customer_id === googleAdsAccount),
    [data, googleAdsAccount]
  );

  const [selectedValue, setSelectedValue] = useState<SelectOption | undefined>(
    undefined
  );
  const [accounts, setAccounts] = useState<SelectOption[] | undefined>();

  const handleSelectedAccount = (selectedID: string) => {
    const selectedAccount = accounts?.find((item) => item.value === selectedID);
    if (selectedAccount) {
      setSelectedValue(selectedAccount);
      dispatch(setSelectedGoogleAds(selectedAccount));
      handleSelectedCampaign('');
    }
  };

  useEffect(() => {
    if (data && !accounts) {
      setAccounts(
        data.data.map((account) => ({
          label: `${account.name} (${account.customer_id})`,
          value: account.customer_id,
        }))
      );
    }
  }, [data, accounts]);

  useEffect(() => {
    if (selectedAccount && !selectedValue) {
      setSelectedValue({
        label: selectedAccount?.name,
        value: selectedAccount?.customer_id,
      });
      dispatch(
        setSelectedGoogleAds({
          label: selectedAccount?.name,
          value: selectedAccount?.customer_id,
        })
      );
    }
  }, [dispatch, selectedAccount, selectedValue]);

  if (error) {
    const { errorMessage } = parseError(error);
    return {
      accounts: [{ label: '', value: '' }],
      activeAccount: { label: '', value: '' },
      handleSelectedAccount: () => null,
      googleAdsError: errorMessage,
    };
  }

  return {
    accounts,
    activeAccount: selectedValue,
    googleAdsLoading: isLoading,
    handleSelectedAccount,
    googleAdsError: '',
  };
};

export default useSelectAccountGoogleADS;
